// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fi-about-index-js": () => import("./../../../src/pages-fi/about/index.js" /* webpackChunkName: "component---src-pages-fi-about-index-js" */),
  "component---src-pages-fi-about-values-js": () => import("./../../../src/pages-fi/about/values.js" /* webpackChunkName: "component---src-pages-fi-about-values-js" */),
  "component---src-pages-fi-contact-js": () => import("./../../../src/pages-fi/contact.js" /* webpackChunkName: "component---src-pages-fi-contact-js" */),
  "component---src-pages-fi-index-js": () => import("./../../../src/pages-fi/index.js" /* webpackChunkName: "component---src-pages-fi-index-js" */),
  "component---src-pages-fi-legal-js": () => import("./../../../src/pages-fi/legal.js" /* webpackChunkName: "component---src-pages-fi-legal-js" */),
  "component---src-pages-fi-news-js": () => import("./../../../src/pages-fi/news.js" /* webpackChunkName: "component---src-pages-fi-news-js" */),
  "component---src-pages-fi-team-js": () => import("./../../../src/pages-fi/team.js" /* webpackChunkName: "component---src-pages-fi-team-js" */),
  "component---src-templates-job-posting-js": () => import("./../../../src/templates/job-posting.js" /* webpackChunkName: "component---src-templates-job-posting-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

